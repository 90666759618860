import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Form = makeShortcode("Form");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "newsletter-château-de-morey-nancy-et-metz"
    }}><strong parentName="h1">{`Newsletter`}</strong>{` Château de Morey `}<strong parentName="h1">{`Nancy`}</strong>{` et `}<strong parentName="h1">{`Metz`}</strong></h1>
    <Form url="https://form.typeform.com/to/E6z3Hd" mdxType="Form" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      